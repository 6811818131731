import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { RoundType } from '@/calendesk/models/RoundType'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FLEXIBLE_BOOKING_3,
      wb_position: 0,
      present_height: 720,
      wb_height__style_height__: 550,
      wb_min_height: 440,
      wb_service_label__text__: trans('wb_dummy_text_select_service'),
      wb_book_now_label__text__: trans('wb_dummy_text_book_now'),
      wb_employee_label__text__: trans('wb_dummy_text_select_employee'),
      wb_employee_info_label__text__: null,
      wb_select_date_hour__html_text__: `<h6 class="text-h6" style="text-align: center">${trans('wb_dummy_text_select_date_and_hour')}</h6>`,
      wb_summary_headline__text__: trans('wb_dummy_text_summary_headline'),
      wb_summary_text__long_text__: trans('wb_default_booking_created_summary'),
      wb_selected_services__text__: trans('wb_dummy_selected_services'),
      wb_init_calendar_title__html_text__: `<h6 class="text-h6" style="text-align: center">${trans('wb_init_calendar_title_example')}</h6>`,
      wb_calendar_min_date__date__: null,
      wb_calendar_max_date__date__: null,
      wb_initial_service_selection__checkbox__: true,
      wb_initial_location_select__checkbox__: true,
      wb_only_services_with_subscriptions__checkbox__: false,
      wb_use_booking_cart__checkbox__: true,
      wb_count_places_in_dates__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false,
      wb_view_flat__checkbox__: false,
      wb_show_service_description__checkbox__: true,
      wb_show_name_surname__checkbox__: true,
      wb_show_phone_number__checkbox__: true,
      wb_require_phone_number__checkbox__: true,
      wb_show_billing_data__checkbox__: true,
      wb_require_billing_data__checkbox__: false,
      wb_show_service_column__checkbox__: true,
      wb_service_list__services__: [],
      wb_view_rounded__select__: {
        type: BuilderSelectType.VIEW_ROUNDED,
        value: RoundType.MD
      },
      wb_booking_dynamic_form__dynamic_form__: [
        {
          type: BookingDynamicFormTypes.TEXT_AREA,
          label: trans('wb_booking_note'),
          required: false,
          equal: null
        }
      ]
    },
    images: []
  }
}
